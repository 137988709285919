<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col xs="0" sm="0" md="7" lg="7" xl="7" class="d-none d-md-block">
        <v-card elevation="0">
          <v-img max-width="100vw" src="/images/happy-client.jpg">
            <span id="welcomeNote">Welcome to Omnicom MediaGroup </span>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <v-card elevation="0">
          <v-card-subtitle class="text-h6 mt-2"
            >Log in to Omnicom MediaGroup</v-card-subtitle
          >
          <v-card-text>
            <!-- <div id="loginOptions">
              <span>Login Using: </span>
              <v-btn elevation="0" color="#df4930">
                <v-icon id="v-btn--icon">mdi-google</v-icon>
              </v-btn>
              <v-btn elevation="0" color="#507cc0">
                <v-icon id="v-btn--icon"> mdi-facebook </v-icon>
              </v-btn>
              <v-btn elevation="0" color="#007bb6">
                <v-icon id="v-btn--icon">mdi-linkedin </v-icon>
              </v-btn>
            </div> -->
            <v-row class="ma-2">
              <v-col><v-divider></v-divider></v-col>
              Or
              <v-col><v-divider></v-divider> </v-col>
            </v-row>
            <v-row>
              <v-progress-linear
                color="secondary"
                :active="loading"
                :indeterminate="loading"
              ></v-progress-linear>

              <v-col>
                <v-form v-model="valid" ref="loginForm">
                  <v-col cols="10" sm="10" md="10">
                    <v-subheader>Email </v-subheader>
                    <v-text-field
                      prepend-icon="mdi-account"
                      type="text"
                      v-model.trim="email"
                      :rules="rules.emailRules"
                      required
                      filled
                      :error-messages="formErrors"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" md="10">
                    <v-subheader>Password </v-subheader>
                    <v-text-field
                      prepend-icon="mdi-lock"
                      v-model.trim="password"
                      :rules="rules.pwdRules"
                      required
                      filled
                      :error-messages="formErrors"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show = !show"
                      :type="show ? 'text' : 'password'"
                      @focus="formErrors = []"
                    >
                    </v-text-field>
                  </v-col>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <div id="suggestion" v-if="formErrors.length">
            <a href="forgot-password"> Forgot your Password ? </a>
          </div> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="submitForm"> Login </v-btn>
            or
            <v-btn to="/register" color="accent"> Register </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getToken } from "@/helpers/utils";

import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  data() {
    return {
      rules: {
        pwdRules: [(v) => !!v || "Password is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },

      show: false,
      loading: false,
      valid: false,
      formErrors: [],
      formErrMsg: "false",

      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["login"]),

    async submitForm() {
      this.$refs.loginForm.validate();
      if (!this.valid) return;

      this.loading = true;

      const res = await this.login({
        email: this.email,
        password: this.password,
      });

      if (!res.user) {
        this.formErrMsg = res.error;
        this.$refs.loginForm.resetValidation();
        this.loading = false;
        return;
      }

      this.loading = false;
      this.$router.push(this.$route.query.returnUrl || "/");
    },
  },
  watch: {
    formErrMsg(val) {
      this.formErrors =
        [val] || "Something wen't wrong. Please contact the System Admin";
      this.loading = false;
    },
  },
  async mounted() {
    const token = await getToken();
    if (token) {
      this.$router.replace("/");
    }
    this.$refs.loginForm.reset();
  },
};
</script>

<style scoped>
.container {
  padding: 0;
  margin: 0;
  background: white;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#welcomeNote {
  position: absolute;
  left: 1.8em;
  right: 68.07%;
  top: 88.06%;
  bottom: 7.22%;
  font-family: Inter;
  font-weight: bold;
  font-size: 1rem;
  color: #ffffff;
}
.v-card__title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
}
.v-card__subtitle {
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
#loginOptions {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
}
#v-btn--icon {
  color: white;
}
.v-btn {
  text-transform: none;
  margin: 10px;
}
#suggestion {
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #1755db;
}
#suggestion a {
  text-transform: none;
  text-decoration: none;
  color: #1755db;
}
</style>
